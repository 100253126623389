import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class AdminAppGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
              private oAuthService: OAuthService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const isAuthenticated = this.oAuthService.hasValidAccessToken();

    if (!isAuthenticated) {
      this.router.navigateByUrl('/auth/admin-login');
    }

    return isAuthenticated;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuthenticated = this.oAuthService.hasValidAccessToken();

    if (!isAuthenticated) {
      this.router.navigateByUrl('/auth/admin-login');
    }

    return isAuthenticated;
  }
}
