import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (next => {
        }),
        (error: HttpErrorResponse) => {
          console.log('httperror:', error);

          if (error.status === 401) {
            this.handleUnauthenticated();
          }

          if (error.status === 403) {
            this.handleUnauthorized();
          }

        }
      )
    );
  }

  private handleUnauthorized(): void {
    // this.router.navigateByUrl('/auth');
  }

  private handleUnauthenticated(): void {
    this.router.navigateByUrl('/auth');
  }

}
