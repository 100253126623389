import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {UserState} from './user.state';
import {take} from 'rxjs/operators';

@Injectable()
export class AuthState {
  private isLoggedInState = false;

  private localStorageAuthKey: string;

  public constructor(
    private oAuthService: OAuthService,
    private userState: UserState,
  ) {
    // this.localStorageAuthKey = this.config.appId + '.auth';
    this.localStorageAuthKey = 'airbynature' + '.auth';
    this.retrieve();
  }

  public isLoggedIn() {
    return this.isLoggedInState;
  }

  public canAccessAdminArea(): boolean {
    let hasAccessToAdminPanel = false;
    this.userState.getUserPermissions().pipe(
      take(1)
    ).subscribe(
      permissions => hasAccessToAdminPanel = permissions.hasAccessToAdminPanel
    ).unsubscribe(); // synchronous access

    return hasAccessToAdminPanel;
  }

  public canAccessUserArea(): boolean {
    return true; // Always true
  }

  public resetLoginState() {
    // Clear all local storage data
    localStorage.removeItem(this.localStorageAuthKey);
    this.oAuthService.logOut();

    this.isLoggedInState = false;
  }

  public login() {
    const payload = {
      isLoggedIn: true,
    };

    this.isLoggedInState = true;

    localStorage.setItem(this.localStorageAuthKey, JSON.stringify(payload));
  }

  private retrieve() {
    const payloadString = localStorage.getItem(this.localStorageAuthKey);
    if ( ! payloadString) {
      return;
    }

    const payload = JSON.parse(payloadString);
    if (payload !== undefined
      && payload !== null) {
      this.isLoggedInState = true;
    }
  }
}
