import {Injectable} from '@angular/core';
import {UserService} from '../../shared/services/user.service';
import {Observable, ReplaySubject} from 'rxjs';
import {User, UserPermissions} from '../../shared/models/user.model';
import {map} from 'rxjs/operators';


@Injectable()
export class UserState {

  private userPermissions: ReplaySubject<UserPermissions> = new ReplaySubject<UserPermissions>(1);
  private loggedInUser: ReplaySubject<User> = new ReplaySubject<User>(1);

  public constructor(
    private userService: UserService,
  ) {
    this.refreshState();
  }

  public refreshState() {
    this.userService.getProfile().subscribe(
      user => {
        this.loggedInUser.next(user);
        this.userPermissions.next(user.meta.permissions);
      }
    );
  }

  public getUserPermissions(): Observable<UserPermissions> {
    return this.userPermissions;
  }

  public getLoggedInUser(): Observable<User> {
    return this.loggedInUser;
  }

}
