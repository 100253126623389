import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthState} from '../states/auth.state';

@Injectable()
export class UserAppGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private authState: AuthState) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const signedIn = this.authState.isLoggedIn();

    if (!signedIn) {
      this.router.navigateByUrl('/auth/app-login');
    }

    return signedIn;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
