import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {User} from '../models/user.model';
import {UserDataInterface} from '../../modules/admin-app/components/user-data/user-data.component';

@Injectable({
  providedIn: 'root'
})

export class UserResource {

  constructor(
    private http: HttpClient
  ) {
  }


  public getProfile(): Observable<User> {
    return this.http
      .get<any>(environment.api.baseUrl + '/profile')
      .pipe(
        map(response => {
          const user = response.data;
          user.meta = response.meta;

          return user;
        })
      );
  }

  public updateUser(id: number, userData: UserDataInterface): Observable<User> {
    return this.http
      .patch<any>(environment.api.baseUrl + `/users/${id}`, userData)
      .pipe(
        map(response => response.data)
      );
  }

  public forgotPassword(email: string): Observable<null> {
    return this.http.post<any>(environment.api.baseUrl + `/forgot-password`, {email});
  }

  public resetPassword(email: string, token: string, password: string, passwordConfirmation: string): Observable<null> {
    return this.http.post<any>(environment.api.baseUrl + `/reset-password`, {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation
    });
  }
}
