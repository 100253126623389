export const environment = {
  production: true,

  api: {
    baseUrl: '/api',
    baseUrlUserApp: '/api/user-app',
    oauth: '/oauth/token'
  },

  defaults: {
    loginEmail: '',
    loginPassword: '',
  }
};
