import {AuthState} from './states/auth.state';
import {NgModule} from '@angular/core';
import {AdminAppGuard} from './guards/admin-app-guard.service';
import {UserAppGuard} from './guards/user-app-guard.service';
import {UserState} from './states/user.state';

const PROVIDERS = [

  // Services

  // Resources

  // States
  AuthState,
  UserState,

  // Guards
  AdminAppGuard,
  UserAppGuard,

  // Interceptors
];


@NgModule({
  providers: PROVIDERS,
})
export class CoreModule {
}
