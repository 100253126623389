import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../models/user.model';
import {UserResource} from '../resources/user.resource';
import {UserDataInterface} from '../../modules/admin-app/components/user-data/user-data.component';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private userResource: UserResource
  ) {
  }

  public getProfile(): Observable<User> {
    return this.userResource.getProfile();
  }


  public updateUser(id: number, userData: UserDataInterface): Observable<User> {
    return this.userResource.updateUser(id, userData);
  }

  public forgottenPassword(email: string): Observable<null> {
    return this.userResource.forgotPassword(email);
  }

  public resetPassword(email: string, token: string, password: string, passwordConfirmation: string): Observable<null> {
    return this.userResource.resetPassword(email, token, password, passwordConfirmation);
  }
}
