import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';

/*
Sentry.init({
  // dsn: 'https://a4d9dbb9475e44b3a23a4ffd7bffd998@o483329.ingest.sentry.io/5736651',
  //integrations: [
  //  new Integrations.BrowserTracing({
  //    tracingOrigins: ['localhost', 'https://yourserver.io/api'],
  //    routingInstrumentation: Sentry.routingInstrumentation,
  //  }),
  //],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  //tracesSampleRate: 0.01,
});
 */

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
