import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
  {
    path: '',
    children: [
      {
        path: 'app',
        loadChildren: () => import('./modules/user-app/user-app.module').then(m => m.UserAppModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/company-select/company-select.module').then(m => m.CompanySelectModule)
      },
      {path: '', redirectTo: 'app', pathMatch: 'full'},
      {path: '**', redirectTo: 'app', pathMatch: 'full'},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
