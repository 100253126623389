import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {OAuthModule, OAuthModuleConfig, OAuthStorage} from 'angular-oauth2-oidc';
import {HttpClientModule} from '@angular/common/http';
import {CoreModule} from './core/core.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {httpInterceptorProviders} from './core/interceptor/interceptor-provider';
import {NgApexchartsModule} from 'ng-apexcharts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    sendAccessToken: true
  }
};

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    OAuthModule.forRoot(authModuleConfig),
    SweetAlert2Module.forRoot(),
    NgApexchartsModule,
    NgbModule,
  ],
  providers: [
    { provide: OAuthStorage, useFactory: storageFactory },
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
